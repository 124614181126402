import React from "react"
import { Button, IconButton, makeStyles } from "@material-ui/core"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import { ExitToApp } from "@material-ui/icons"
import { useTranslation } from "react-i18next"

interface Props {
  onLogout: () => void
  width: Breakpoint
  light?: boolean
}

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: ({ lightText }) =>
      lightText ? palette.primary.contrastText : "initial",
  },
}))

const LogoutButtonTemplate = ({ width, onLogout, light }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles({ lightText: light })

  return isWidthUp("md", width) ? (
    <Button
      startIcon={<ExitToApp className={classes.icon} />}
      onClick={onLogout}
      className={classes.icon}
    >
      {t("button.logout")}
    </Button>
  ) : (
    <IconButton onClick={onLogout}>
      <ExitToApp className={classes.icon} />
    </IconButton>
  )
}

export const LogoutButton = withWidth()(LogoutButtonTemplate)
